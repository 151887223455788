import { Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import * as Clickable from "components/clickable"
import { navigate } from "gatsby"
import { Typography } from "components/typography"
import { apis as API } from "@sog/sdk"

const UpdatePropertyDetails = (props: any) => {
  const locationState = ((props.location?.state as any) || {})
  const jsonInput = locationState.jsonInput
  const progress = locationState.progress
  const existingProperties = locationState.existingProperties
  const isPartner = locationState.isPartner

  const [newProperties, setNewProperties] = useState(locationState?.existingProperties || [])
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0)
  const [updateComplete, setUpdateComplete] = useState(false)

  console.log("--------------------------------")
  console.log(locationState)

  const [title, setTitle] = useState("")
  const [paragraph, setParagraph] = useState("")

  const titleList = [
    "Add new property to portfolio",
    "Update property details",
  ]

  const paragraphList = [
    "Your portfolio already includes properties*.\nSelect 'confirm' to add this new property.",
    "You submitted this property previously*.\nSelect 'confirm' to update its details.",
  ]

  const callUpdateUserProducts = (properties: any, updateMode: string) => {
    const timestamp = Math.floor(Date.now() / 1000)
    properties.map((property: any) => {
      property.timestamp = timestamp
    })
    API.updateUserProducts({ properties: properties, updateMode: updateMode }, results => { })
  }

  const isDuplicateProperty = () => {
    const propertyIndex = existingProperties.findIndex((property: any) => (
      property.address1 === jsonInput.properties[currentPropertyIndex].address1 &&
      property.address2 === jsonInput.properties[currentPropertyIndex].address2
    ))

    return propertyIndex
  }

  useEffect(() => {
    let index = 0
    if (isDuplicateProperty() !== -1) {
      index = 1
    } else {
      index = 0
    }
    setTitle(titleList[index])
    setParagraph(paragraphList[index])
  }, [currentPropertyIndex])

  useEffect(() => {
    if (updateComplete) {
      callUpdateUserProducts(newProperties, "OVERWRITE")
      jsonInput.properties = newProperties
      if (isPartner) {
        navigate("/home-loans/notifying", {
          state: { ...locationState },
        })
      } else {
        navigate("/home-loans/offers", {
          state: {
            jsonInput: jsonInput,
            progress: progress,
            updateMode: "OVERWRITE",
          },
          replace: true,
        })
      }
    }
  }, [updateComplete])

  return (
    <div className="page-container">
      <div className="survey-container">
        <Stack style={{ textAlign: "center" }}>
          <Typography.H4>{title}</Typography.H4>

          <Typography.P comp="medium-black" style={{ marginTop: "20px" }}>
            {jsonInput.properties[currentPropertyIndex].address1},
            <br />
            {jsonInput.properties[currentPropertyIndex].address2}
          </Typography.P>

          <Typography.P comp="medium-tight" style={{ marginTop: "20px" }}>
            {paragraph}
          </Typography.P>
          <Stack>
            <Clickable.Text
              comp={3}
              style={{
                height: "56px",
                width: "100%",
                marginTop: "60px",
              }}
              onClick={() => {
                setNewProperties((properties: any) => {
                  const propertyIndex = isDuplicateProperty()
                  if (propertyIndex !== -1) {
                    return properties.map((property: any, i: number) => (
                      i === propertyIndex ? jsonInput.properties[currentPropertyIndex] : property
                    ))
                  } else {
                    return [...properties, jsonInput.properties[currentPropertyIndex]]
                  }
                })
                if (currentPropertyIndex === jsonInput.properties.length - 1) {
                  setUpdateComplete(true)
                } else {
                  setCurrentPropertyIndex(currentPropertyIndex + 1)
                }
              }}
            >
              {currentPropertyIndex === jsonInput.properties.length - 1 ? "Confirm" : "Continue"}
            </Clickable.Text>
            <Clickable.Text
              comp={3}
              secondary
              style={{
                height: "56px",
                width: "100%",
                marginTop: "20px",
              }}
              onClick={() => {
                if (currentPropertyIndex === jsonInput.properties.length - 1) {
                  setCurrentPropertyIndex(currentPropertyIndex + 1)
                } else {
                  navigate("/")
                }
              }}
            >
              Cancel
            </Clickable.Text>
          </Stack>
        </Stack>
        <Typography.P comp="medium-tight" style={{ marginTop: "20px" }}>
          * Review, update or delete existing properties by signing in to your dashboard (top right).
        </Typography.P>
      </div>
    </div >
  )
}

export default UpdatePropertyDetails
