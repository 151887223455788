import { Typography } from "components/typography"
import React, { useEffect, useRef } from "react"
import { apis as API } from "@sog/sdk"
import { navigate } from "gatsby-link"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Notifying = (props: any) => {
  const properties = props.location.state.jsonInput.properties
  const rateGap = props.location.state.rateGap
  const providerId = properties[0].provider.id
  const providerName = properties[0].provider.name

  const onSuccess = (responseJson: any) => {
    console.log("successdsfalkjdl")
    console.log(responseJson)

    const expireDate = responseJson.expireDate
    const date = new Date(expireDate)
    const options = { weekday: 'long', day: 'numeric', month: 'long', timeZone: 'Australia/Sydney' };
    const formatter = new Intl.DateTimeFormat('en-AU', options);
    const formattedDate = formatter.format(date);
    navigate("/home-loans/what-happens-next", {
      state: {
        providerName: providerName,
        expireDate: formattedDate,
      }
    })
  }

  const onError = (error: any) => {
    // navigate("/home-loans/review-in-progress", {
    //   state: {
    //     responseJson: error,
    //     hasRequest: true,
    //   }
    // })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      API.reviewCurrentHLDeal({ properties: properties, rateGap: rateGap }, onSuccess, onError)
    }, 3000);

    return () => clearTimeout(timer);
  }, []);



  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey" style={{ textAlign: "center" }}>
          Notifying {providerName}
        </Typography.H3>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ marginBottom: "50px", marginTop: "50px", width: "100%", display: "flex", alignItems: "center", height: "50px", justifyContent: "center" }}>
            <div style={{ width: "70px" }}>
              <LazyLoadImage src="/images/logo.png" style={{ height: "50px", margin: "0 auto" }} alt="Stay or Go Logo" />
            </div>
            <div style={{ maxWidth: "30%", display: "flex", justifyContent: "center", marginLeft: "5%", marginRight: " 5%" }}>
              <LazyLoadImage src="/images/icon_double_arrow.png" style={{ width: "40px", height: "40px" }} alt="" />
            </div>
            <div style={{ width: "70px" }}>
              <LazyLoadImage src={`/images/provider/${providerId}.png`} alt={`${providerName} Logo`} style={{ height: "40px", margin: "0 auto" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifying
