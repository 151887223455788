import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Typography } from "components/typography"
import { HomeLoanTopPicks as TopPicks } from "components/content/top-picks"
import * as Clickable from "components/clickable"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import AppManager from "services/app-manager"
import { HomeLoanDisclaimer } from "components/content/content-disclaimer"
import { ListHeaderProvider } from "components/content/list-header"
import { compileSchemaOrgQa } from "components/schema"
import ReactPlayer from "react-player"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import * as styles from "./style.module.scss"
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos"
import ProductCategoryPageTopSection from "../product-category-page-top-section"
import { SectionTextDivider, SectionTextSubdivider } from "../product-category-page-section-header"

export const HomeLoansView = (props: { indexPage?: boolean }) => {
  const isWide = useMediaQuery("(min-width:768px)")

  const dateUpdated = AppManager.getInstance().getProductCategoryDateUpdated("hl")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated ? dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4) : ""

  const chunkSize = isWide ? 4 : 2
  const features = [
    { name: "Best Rate", urlSlug: "best-home-loan-rates", img: "/images/icon_best_rate.png" },
    { name: "Owner Occupied", urlSlug: "owner-occupied-home-loans", img: "/images/icon_home.png" },
    { name: "Investment", urlSlug: "investment-home-loans", img: "/images/icon_investment.png" },
    { name: "Fixed Rate", urlSlug: "fixed-rate-home-loans", img: "/images/icon_fixed_rate.png" },
    { name: "Variable Rate", urlSlug: "variable-rate-home-loans", img: "/images/icon_variable_rate.png" },
    { name: "Low Deposit", urlSlug: "low-deposit-home-loans", img: "/images/icon_low_deposit.png" },
    { name: "Interest Only", urlSlug: "interest-only-home-loans", img: "/images/low_rate.png" },
    { name: "Cashback", urlSlug: "home-loan-cashback-offers", img: "/images/cash_back.png" },
    // { name: "Offset", urlSlug: "offset-home-loans", img: "/images/icon_offset.png" },
  ]
  const featureLines = chunkifyArray(features, chunkSize)

  const hlProviders: object = AppManager.getInstance().getReducedHomeLoanProviders()
  const mainItems = Object.values(hlProviders)
    .filter((x: any) => x.big4 && x.popular && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const otherItems = Object.values(hlProviders)
    .filter((x: any) => !x.big4 && (!props.indexPage || x.popular) && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const majors = chunkifyArray(mainItems, chunkSize)
  const popular = chunkifyArray(otherItems, chunkSize)

  const queryResult = useStaticQuery(graphql`
    {
      allContentfulContentQa {
        nodes {
          slug
          categoryName
          categorySummary
          qa {
            qa
          }
        }
      }
    }
  `)
  const qa = queryResult.allContentfulContentQa.nodes.find((x: any) => x.slug === "/home-loans")
  const qaList: [] = queryResult.allContentfulContentQa.nodes.filter((x: any) => x.slug.match(/^\/home-loans\/\d+\//) !== null)
  // const qaList: [] = queryResult.allContentfulContentQa.nodes.filter((x: any) => x.slug.indexOf("/faq-test/") === 0)
  qaList.sort((a: any, b: any) => {
    const orderA = parseInt(a.slug.split("/")[2])
    const orderB = parseInt(b.slug.split("/")[2])
    return (isNaN(orderA) ? Number.MAX_VALUE : orderA) - (isNaN(orderB) ? Number.MAX_VALUE : orderB)
  })

  const faqCategoryDetails: { [key: string]: any } = {
    general: {
      name: "I want to learn more",
      description: "General information you might need to know about home loans, including term lengths, repayments and minimum deposits",
    },
    "first-home-buyer": {
      name: "I'm buying my first home",
      description: "Everything you need to know as a first time home buyer",
    },
    refinance: {
      name: "Refinancing home loans",
      description: "Learn about the application process, miscellaneous fees, implications and how much you can save by refinancing",
    },
    application: {
      name: "Applying for a home loan",
      description: "The documents you'll need, credit score requirements and minimum deposit amount",
    },
  }

  const faqContent: any[] = []
  qaList.forEach((x: any) => {
    const slugEnding: string = x.slug.split("/")[3]
    if (x.categoryName) {
      faqContent.push({
        categoryName: x.categoryName,
        categoryDescription: x.categorySummary || "",
        categoryContentMarkdown: x.qa.qa,
      })
      // } else if (slugEnding in faqCategoryDetails) {
      //   faqContent.push({
      //     categoryName: faqCategoryDetails[slugEnding].name,
      //     categoryDescription: faqCategoryDetails[slugEnding].description,
      //     categoryContentMarkdown: x.qa.qa
      //   })
      // }
      // else {
      //   // TEST
      //   faqContent.push({
      //     categoryName: slugEnding,
      //     categoryDescription: "Test",
      //     categoryContentMarkdown: x.qa.qa
      //   })
    }
  })

  let minRate = Number.MAX_VALUE
  let minComparisonRate = Number.MAX_VALUE
  let maxCashBackRefinance = 0

  // find the top 1% comparison rate, then find the lowest interest rate among them
  let numTopOnePercent = Math.ceil(Object.values(hlProviders).length * 0.01)
  let minComparisonRates: number[] = []
  
  Object.values(hlProviders).forEach(provider => {
    if (provider.maxCashBackRefinance > maxCashBackRefinance) {
      maxCashBackRefinance = provider.maxCashBack
    }

    minComparisonRates.push(provider.minComparisonRate)
    if (minComparisonRates.length > numTopOnePercent) {
      let maxIndex = minComparisonRates.indexOf(Math.max(...minComparisonRates))
      minComparisonRates.splice(maxIndex, 1)
    }

    let topOnePercentComparisonRate = Math.max(...minComparisonRates)
    if (provider.minComparisonRate <= topOnePercentComparisonRate) {
      if (provider.minRate < minRate) {
        minRate = provider.minRate
        minComparisonRate = provider.minComparisonRate
      }
    }
  })

  let description = "Get a better home loan deal from your bank and across the market. "
  description += "Home loan rates from " + (minRate * 100).toFixed(2).toLocaleString() + "% p.a. (" + (minComparisonRate * 100).toFixed(2).toLocaleString() + "% p.a. comparison rate*). "
  description += "Market leading offers including fee waivers"
  if (maxCashBackRefinance) {
    description += " and up to $" + maxCashBackRefinance.toLocaleString() + " cash back when you refinance"
  }
  description += "."

  const landingPageTextView = (num: number) => {
    return (
      <div>
        <div>
          <Typography.H2>{num === 0 ? "Get a better deal from your bank" : ""}</Typography.H2>
          <Typography.P comp="large">{num === 0 ? "Get a better home loan deal without having to switch, and compare leading offers from across the market." : ""}</Typography.P>
        </div>
      </div>
    )
  }

  const getVideo = (num: number) => {
    const videos = [
      {
        url: "/video/StayOrGo_Animation_HomeLoan_V4.mp4",
        placeholder: undefined,
        alt: "Get a better home loan deal from your bank",
      },
    ]

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPlayer playing={true} url={videos[num].url} volume={0} loop={true} playsinline={true} width="100%" height="100%" />
        {/* <img style={{maxWidth: "100%"}} src={videos[num].placeholder} alt={videos[num].alt} /> */}
      </div>
    )
  }

  const renderTextVideo = (num: number) => {
    return (
      <div className={styles.topSectionContainer}>
        <div className={styles.topSectionTextContentContainer}>
          <Typography.H3 comp="article">Get a better deal from your bank</Typography.H3>
          <Typography.P comp="large">Get a better home loan deal without having to switch, and compare leading offers from across the market.</Typography.P>
          <div className={styles.getStartedContainer}>
            <Clickable.Text
              comp={1}
              component={Link}
              to={"/home-loans/instructions/"}
              className="landing-page-button"
              style={{ width: "100%" }}
              onClick={() => {
                DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOME_LOANS", productCategory: "HOME_LOANS" })
              }}
            >
              <div style={{ margin: "auto" }}>
                Get Started <ArrowForwardIos style={{ fontSize: "50%" }} />
              </div>
            </Clickable.Text>
            <Typography.P comp="small">
              <div className={styles.getStartedHelpText}>It's easy, and takes just a few minutes!</div>
            </Typography.P>
          </div>
        </div>
        <div className={styles.topSectionImageContentContainer}>{getVideo(num)}</div>
      </div>
    )
  }

  const sectionTextDivider = (heading: string, text: string, style?: React.CSSProperties) => {
    return (
      <div className={styles.sectionTextDivider} style={{ ...style }}>
        <Typography.H2 comp="external-landing-page-section-heading">{heading}</Typography.H2>
        <Typography.P comp="large">{text}</Typography.P>
      </div>
    )
  }

  const sectionTextSubdivider = (subheading: string, text: string, style?: React.CSSProperties) => {
    return (
      <div className={styles.sectionTextSubdivider} style={{ ...style }}>
        <Typography.H6 comp="provider-page-subheading">{subheading}</Typography.H6>
        <div style={{ marginTop: "10px", marginBottom: "20px" }}>
          <Typography.P comp="large">{text}</Typography.P>
        </div>
      </div>
    )
  }

  return (
    <div>
      {!props.indexPage && (
        <ProductCategoryPageTopSection
          productCategory="hl"
          dateString={dateString}
          pageHeading={`Compare home loans starting from ${(minRate * 100).toFixed(2).toLocaleString()}% p.a. (comparison rate ${(minComparisonRate * 100).toFixed(2).toLocaleString()}% p.a.)`}
          categoryDescriptionHeading="Get a better deal from your bank"
          categoryDescription="Get a better home loan deal without having to switch, and compare leading offers from across the market."
          imageOrVideoComponent={getVideo(0)}
          surveyUrlSlug="/home-loans/instructions/"
          surveyButtonOnClick={() => {
            DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOME_LOANS", productCategory: "HOME_LOANS" })
          }}
        />
      )}
      {!props.indexPage ? (
        <div>
          <div style={{ marginTop: isWide ? "220px" : "45px", marginBottom: "130px" }}>
            <SectionTextDivider heading="Compare Home Loans by feature" text={`Explore and compare top home loan options from 30+ lenders, starting at ${(minRate * 100).toFixed(2).toLocaleString()}% (comparison rate ${(minComparisonRate * 100).toFixed(2).toLocaleString()}%). Find the perfect loan for your investment, refinancing, or home purchase needs with ease.`} />
            <MapToDiv.Features
              features={featureLines}
              onClickAdditionalAction={feature => {
                const appManager = AppManager.getInstance()
                if (feature.name === "Low Deposit") {
                  appManager.setHomeLoanAmount(500000)
                  appManager.setLoanDeposit(5)
                  appManager.setLoanPurpose("Home")
                } else if (feature.name === "Investment") {
                  appManager.setHomeLoanAmount(500000)
                  appManager.setLoanDeposit(40)
                  appManager.setLoanPurpose("Investment")
                } else {
                  appManager.setHomeLoanAmount(500000)
                  appManager.setLoanDeposit(40)
                  appManager.setLoanPurpose("Home")
                }
              }}
            />
          </div>
          <TopPicks />
        </div>
      ) : null}

      <div style={{ marginTop: "110px" }} id="providers">
        <SectionTextDivider heading="Compare leading home loan lenders" text="Explore a wide range of home loan offers from major banks and popular lenders." />
        <SectionTextSubdivider subheading="Majors" text="Big banks with extensive nationwide networks that provide a comprehensive range of services" />
        <MapToDiv.Banks banks={majors} productCategory="home-loans" />
        <SectionTextSubdivider subheading="Challengers" text="Regional and international banking institutions that offer financial services" />
        <MapToDiv.Banks banks={popular} productCategory="home-loans" />
      </div>

      {/* <div style={{ marginTop: "120px" }}>
        <Typography.H2>Latest News & Articles</Typography.H2>
        <div style={{ marginTop: "50px", lineHeight: "1.1" }}>
          <Link to={"/why-the-longest-balance-transfer-is-not-always-best"} style={{ fontSize: "20px", fontFamily: "Arboria-Bold,sans-serif", color: "#4f8ccc", textDecoration: "inherit" }}>
            Why the longest Balance Transfer is not always best
          </Link>
          <Typography.P style={{ marginTop: "10px" }}>0% balance transfer offers with the longest term are not necessarily the best. Find out why, and learn how to choose the right option.</Typography.P>
        </div>
        <div style={{ marginTop: "35px", lineHeight: "1.1" }}>
          <Link to={"/are-rewards-credit-cards-actually-worth-it"} style={{ fontSize: "20px", fontFamily: "Arboria-Bold,sans-serif", color: "#4f8ccc", textDecoration: "inherit" }}>
            Are rewards credit cards actually worth it?
          </Link>
          <Typography.P style={{ marginTop: "10px" }}>A rewards card can help you earn flights, travel and more. But are they worth the extra cost compared to no-frills cards?</Typography.P>
        </div>
      </div> */}

      {!props.indexPage && faqContent.length > 0 ? (
        <div>
          <div style={{ marginTop: "100px" }}>
            <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
            <Faq faqContent={faqContent} />
          </div>
          <HomeLoanDisclaimer isDisclaimerOpen isProvider />
        </div>
      ) : null}
    </div>
  )
}

const HomeLoansPage = () => {
  return (
    <div className="container-center">
      <div className="home-container">
        <div>
          <HomeLoansView />
        </div>
      </div>
    </div>
  )
}

export default HomeLoansPage
