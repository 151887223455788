import { Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import * as Clickable from "components/clickable"
import { navigate } from "gatsby"
import { Typography } from "components/typography"
import { apis as API } from "@sog/sdk"

const ReviewInProgress = (props: any) => {
  return (
    <div className="page-container">
      <div className="survey-container">
        <Stack style={{ textAlign: "center" }}>
          <Typography.H4>Review already in progress</Typography.H4>


          <Typography.P comp="medium-tight" style={{ marginTop: "20px" }}>
            You already have a home loan review underway. Please wait for this process to complete before submitting a new request.
          </Typography.P>

          <Clickable.Text
            comp={3}
            style={{
              height: "56px",
              width: "100%",
              marginTop: "60px",
            }}
            onClick={() => {
              navigate("/")
            }}
          >
            Home
          </Clickable.Text>

        </Stack>
      </div>
    </div >
  )
}

export default ReviewInProgress
