import React, { useState, useEffect, useRef, useMemo } from "react"
import { Typography } from "components/typography"
import ProgressBar from "components/survey/progress-bar"
import axios from "axios"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Grid from "@mui/material/Grid"
import { debounce } from "@mui/material/utils"
import * as styles from "./style.module.scss"
import { navigate } from "gatsby"
import { createTheme, Stack } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import { Select, MenuItem } from "@mui/material"
import * as Clickable from "components/clickable"

const theme = createTheme({
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontFamily: "Arboria-Book, sans-serif",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "13px",
        },
      },
    },
  },
})

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
}

const AboutYourProperty = (props: any) => {
  const API_KEY = "AIzaSyBXHql-vrsFA23oBtMTOxUrt_tL4FR8Is0"
  const store = useStore()

  const progress = props.location.state.progress || 0

  // google map api
  const [value, setValue] = useState<PlaceType | null>(null)
  const [inputValue, setInputValue] = useState("")
  const [options, setOptions] = useState<readonly PlaceType[]>([])
  const loaded = useRef(false)
  const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]

  const [currentIndex, setCurrentIndex] = useState(0)

  const validStates = ["NSW", "VIC", "QLD", "SA", "WA", "TAS", "ACT", "NT"]
  const [multipleProperties, setMultipleProperties] = useState(store.multipleProperties || false)
  const [properties, setProperties] = useState(store.properties || [generatePropertyObject("OWNER_OCCUPIED"), generatePropertyObject("INVESTMENT")])
  const [propertyAddresses, setPropertyAddresses] = useState(store.propertyAddresses || {})

  function generatePropertyObject(purpose: string) {
    return {
      address1: "",
      address2: "",
      addressApiResponse: null,
      addressEnteredManually: false,
      value: "",
      purpose: purpose,
      addressError: false,
      addressDuplicateError: false,
      valueError: false,
    }
  }

  const [latitude, setLatitude] = useState(store.latitude || null)
  const [longitude, setLongitude] = useState(store.longitude || null)
  const [coordinatesFetched, setCoordinatesFetched] = useState(store.coordinatesFetched || false)

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=Function.prototype`, document.querySelector("head"), "google-maps")
    }

    loaded.current = true
  }

  const fetch = useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        ;(autocompleteService.current as any).getPlacePredictions(request, callback)
      }, 400),
    [],
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  // geoapify api
  const autocompleteRef = useRef(null)
  const [inputPropertyAddress, setInputPropertyAddress] = useState("")
  const [autocompleteResults, setAutocompleteResults] = useState(null)
  // const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  // const [lastAddressQuery, setLastAddressQuery] = useState("")

  const useStyles = () => ({
    inputField: {
      width: "100%",
      fontFamily: "Arboria-Bold, sans-serif",
      fontSize: "18px",
      color: "#202020",
      // backgroundColor: "rgba(112, 112, 112, 0.25)",
      height: "60px",
      paddingLeft: "30px",
      paddingRight: "10px",
    },
    manualInputField: {
      width: "100%",
      fontFamily: "Arboria-Book, sans-serif",
      fontSize: "18px",
      color: "#202020",
      // backgroundColor: "rgba(112, 112, 112, 0.25)",
      height: "60px",
      paddingRight: "10px",
    },
    dropdownField: {
      width: "30%",
      height: "56px",
      fontFamily: "Arboria-Book, sans-serif",
      fontSize: "18px",
      color: "#202020",
    },
  })
  const classes = useStyles()

  // const [averageQueryTime, setAverageQueryTime] = useState({
  //   totalQueryTime: 0,
  //   queriesPerformed: 0
  // })

  const autoCompleteAddress = () => {
    if (properties[currentIndex].address1.length + properties[currentIndex].address2.length > 0) {
      const loadAutocomplete = async () => {
        // test api key
        // const start = Date.now()
        const url = "https://api.geoapify.com/v1/geocode/autocomplete?text=" + properties[currentIndex].address1 + properties[currentIndex].address2 + "&filter=countrycode:au&format=json&apiKey=204e7ef6395b48359d2d31c5a930e1be&type=amenity" + (latitude !== null && longitude !== null ? `&bias=proximity:${longitude},${latitude}` : "&bias=countrycode:au")
        const response = await axios.get(url)
        setAutocompleteResults(response)
        // const end = Date.now()
        // const newQueryTime = {
        //   totalQueryTime: averageQueryTime.totalQueryTime + end - start,
        //   queriesPerformed: averageQueryTime.queriesPerformed + 1
        // }
        // console.log(`Fetched autocomplete data in ${end - start}ms (running average: ${newQueryTime.totalQueryTime / newQueryTime.queriesPerformed}ms)`)
        // setAverageQueryTime(newQueryTime)
      }
      loadAutocomplete()
    }
  }

  const timeoutInterval = 300
  // const queryAutocompleteTimerFunction = () => {
  //   autoCompleteAddress()
  //   setLastAddressQuery(inputPropertyAddress)
  //   setTimeoutId(null)
  // }

  // useEffect(() => {
  //   if (lastAddressQuery !== inputPropertyAddress) {
  //     setTimeoutId(setTimeout(queryAutocompleteTimerFunction, timeoutInterval))
  //   }
  // }, [lastAddressQuery])

  useEffect(() => {
    // autoCompleteAddress()
    const timeOutId = setTimeout(() => autoCompleteAddress(), timeoutInterval)
    return () => clearTimeout(timeOutId)
    // if (timeoutId === null) {
    //   setTimeoutId(setTimeout(queryAutocompleteTimerFunction, timeoutInterval))
    // }
  }, [inputPropertyAddress])

  // on first load
  useEffect(() => {
    if (!coordinatesFetched) {
      API.getUserLocation((response: any) => {
        setLatitude(response.latitude)
        setLongitude(response.longitude)
        setCoordinatesFetched(true)
        store.latitude = response.latitude
        store.longitude = response.longitude
        store.coordinatesFetched = true
      })
    }
    // return () => {
    //   if (timeoutId !== null) {
    //     clearTimeout(timeoutId)
    //   }
    // }
    const newProperties = [...properties]
    newProperties.map((p, i) => {
      p.addressEnteredManually = false
    })
    setProperties(newProperties)
  }, [])

  const dataCollectorPropertiesUpdated = (newProperties: any) => {
    if (!multipleProperties) {
      DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { surveyId: store.getState().survey.surveyId, properties: newProperties[0] })
    } else {
      DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { surveyId: store.getState().survey.surveyId, properties: newProperties })
    }
  }

  const updatePropertyAddress = (address1: string, address2: string, index: number) => {
    setInputPropertyAddress(address1 + address2)
    setCurrentIndex(index)
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          address1: address1,
          address2: address2,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    store.properties = newProperties
    dataCollectorPropertiesUpdated(newProperties)
  }

  const updateMultipleProperties = (value: boolean) => {
    setMultipleProperties(value)
    store.multipleProperties = value
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { surveyId: store.getState().survey.surveyId, multipleProperties: value })
  }

  const updatePropertyValue = (value: string, index: number) => {
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          value: value,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    store.properties = newProperties
    dataCollectorPropertiesUpdated(newProperties)
  }

  const updatePropertyPurpose = (value: string, index: number) => {
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          purpose: value,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    store.properties = newProperties
    dataCollectorPropertiesUpdated(newProperties)
  }

  const updateAddressEnteredManually = (value: boolean, index: number) => {
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          addressEnteredManually: value,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    store.properties = newProperties
    dataCollectorPropertiesUpdated(newProperties)

    if (!(index in propertyAddresses)) {
      const newPropertyAddresses = { ...propertyAddresses }
      newPropertyAddresses[index] = {
        "Unit Number": "",
        "Street Number": "",
        "Street Name": "",
        "Street Type": "",
        Suburb: "",
        State: "",
        Postcode: "",
        "Unit Number Error": false,
        "Street Number Error": false,
        "Street Name Error": false,
        "Street Type Error": false,
        "Suburb Error": false,
        "State Error": false,
        "State Invalid Error": false,
        "Postcode Error": false,
        "Postcode Invalid Error": false,
      }
      setPropertyAddresses(newPropertyAddresses)
      store.propertyAddresses = newPropertyAddresses
      DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { surveyId: store.getState().survey.surveyId, propertyAddresses: newPropertyAddresses })
    }
  }

  const autocompleteOnClick = (result: any) => {
    const address1 = result.address_line1
    const address2 = result.address_line2.replace(", Australia", "")
    autocompleteRef.value = address1 + " " + address2
    const newProperties = properties.map((p, i) => {
      if (i === currentIndex) {
        return {
          ...p,
          address1: address1,
          address2: address2,
          addressApiResponse: result,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    store.properties = newProperties
    dataCollectorPropertiesUpdated(newProperties)
    setInputPropertyAddress("")
    setAutocompleteResults(null)
  }

  const formatPropertyValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/\D+/g, "")
    if (value === "") {
      e.target.value = ""
    } else {
      e.target.value = Number(value).toLocaleString()
    }
  }

  const updateManualPropertyAddress = (value: string, label: string, index: number) => {
    const newPropertyAddresses = { ...propertyAddresses }
    newPropertyAddresses[index][label] = value
    setPropertyAddresses(newPropertyAddresses)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { surveyId: store.getState().survey.surveyId, propertyAddresses: newPropertyAddresses })
  }

  const updatePostcode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value.replace(/\D+/g, "").slice(0, 4)
    if (value === "") {
      e.target.value = ""
    } else {
      e.target.value = value
    }
    updateManualPropertyAddress(value, "Postcode", index)
  }

  const addressField = (label: string, required: boolean, error: boolean, index: number) => {
    const isPostcode = label === "Postcode"
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ height: "60px", backgroundColor: "#FFFFFF", paddingLeft: "10px", outline: error ? "3px solid #F9689C" : "none" }}>
        <Typography.P fontColor="#202020" style={{ width: "40%" }}>
          {label}
        </Typography.P>
        {label == "State" ? (
          <div className={styles.inputFieldContainer}>
            <Select
              defaultValue={location}
              variant="standard"
              disableUnderline
              className={styles.inputField}
              style={classes.dropdownField}
              // input={fontSize:2}
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={e => updateManualPropertyAddress(e.target.value, label, index)}
            >
              {states.map((o, i) => {
                return (
                  <MenuItem key={i} value={o} style={{ fontSize: "18px", fontFamily: "Arboria-Book, sans-serif" }}>
                    {o}
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        ) : (
          <TextField
            variant="standard"
            autoComplete="off"
            placeholder={required ? "Required" : "Optional"}
            // inputRef={loanAmountRef}
            InputProps={{
              disableUnderline: true,
              style: classes.manualInputField,
            }}
            inputProps={{
              style: { textAlign: "right" },
              inputMode: isPostcode ? "decimal" : "text",
              pattern: isPostcode ? "d{4,4}" : "*",
            }}
            onChange={e => (isPostcode ? updatePostcode(e, index) : updateManualPropertyAddress(e.target.value, label, index))}
          />
        )}
      </Stack>
    )
  }

  const removeProperties = (index: number) => {
    const newProperties = properties.filter((_, i) => i !== index)
    setProperties(newProperties)
    store.properties = newProperties
    dataCollectorPropertiesUpdated(newProperties)
  }

  const getPropertyValueError = (value: any) => {
    const strippedValue = `${value}`.replace(/\D/g, "")
    if (strippedValue === "") {
      return "Property value must be specified"
    }
    const valAsNumber = Number(strippedValue)
    if (Number.isNaN(valAsNumber) || !Number.isFinite(valAsNumber)) {
      return "Property value is invalid"
    }
    if (valAsNumber < 25000) {
      return "Property value must be at least $25,000"
    }
    return null
  }

  const validateInputs = () => {
    let noError = true
    const length = multipleProperties ? properties.length : 1
    const newProperties = structuredClone(properties)
    const uniqueAddresses = {}

    for (let i = 0; i < length; i++) {
      const p = newProperties[i]
      if (p.addressEnteredManually) {
        // if (propertyAddresses[i]["Street Number"].length === 0) {
        //   propertyAddresses[i]["Street Number Error"] = true
        //   noError = false
        // } else {
        //   propertyAddresses[i]["Street Number Error"] = false
        // }
        Object.keys(propertyAddresses[i])
          .slice(1, 7)
          .map(k => {
            if (propertyAddresses[i][k].length === 0) {
              propertyAddresses[i][k + " Error"] = true
              noError = false
            } else {
              propertyAddresses[i][k + " Error"] = false
            }
            if (k === "State" && propertyAddresses[i][k].length > 0) {
              if (!validStates.includes(propertyAddresses[i][k])) {
                propertyAddresses[i][k + " Invalid Error"] = true
                noError = false
              } else {
                propertyAddresses[i][k + " Invalid Error"] = false
              }
            } else if (k === "Postcode" && propertyAddresses[i][k].length > 0) {
              if (propertyAddresses[i][k].length !== 4) {
                propertyAddresses[i][k + " Invalid Error"] = true
                noError = false
              } else {
                propertyAddresses[i][k + " Invalid Error"] = false
              }
            }
          })
      } else {
        if (!p.address1 || !p.address2) {
          p.address1 = ""
          p.address2 = ""
          p.addressError = true
          noError = false
        } else {
          p.addressError = false
        }
      }

      const key = p.address1 + p.address2

      if (!(key in uniqueAddresses)) {
        uniqueAddresses[key] = i
        p.addressDuplicateError = false
      } else {
        p.addressDuplicateError = true
        noError = false
      }

      const propertyValueError = getPropertyValueError(p.value)
      if (propertyValueError === null) {
        p.valueError = false
      } else {
        p.valueError = true
        noError = false
      }
    }

    setProperties(newProperties)
    store.properties = newProperties
    return noError
  }

  const cleanInputs = () => {
    let jsonInput = properties
    if (!multipleProperties) {
      jsonInput = properties.slice(0, 1)
    }
    jsonInput.map((p, i) => {
      if (p.addressEnteredManually) {
        p.propertyNumber = (propertyAddresses[i]["Unit Number"] ? propertyAddresses[i]["Unit Number"] + "/" : "") + propertyAddresses[i]["Street Number"]
        p.street = propertyAddresses[i]["Street Name"] + " " + propertyAddresses[i]["Street Type"]
        p.country = "Australia"
        p.postcode = propertyAddresses[i]["Postcode"]
        p.suburb = propertyAddresses[i]["Suburb"]
        p.lon = null
        p.lat = null
        p.state = propertyAddresses[i]["State"]

        p.address1 = p.propertyNumber + " " + p.street
        p.address2 = p.suburb + " " + p.state + " " + p.postcode
      } else {
        p.propertyNumber = p.addressApiResponse.housenumber
        p.street = p.addressApiResponse.street
        p.country = p.addressApiResponse.country
        p.postcode = p.addressApiResponse.postcode
        p.suburb = p.addressApiResponse.city
        p.lon = p.addressApiResponse.lon
        p.lat = p.addressApiResponse.lat
        p.state = p.addressApiResponse.state_code
      }

      p.value = Number(p.value.replaceAll(",", ""))

      delete p.addressApiResponse
      delete p.addressError
      delete p.addressDuplicateError
      delete p.valueError
    })

    return jsonInput
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey">Your property with a loan</Typography.H3>

        <ProgressBar maxSteps={4} progress={progress} />

        <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px", marginTop: "60px", marginBottom: "40px" }}>
          <button
            onClick={() => {
              updateMultipleProperties(false)
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: !multipleProperties ? "#66CFDC" : "#FFFFFF", color: !multipleProperties ? "#FFFFFF" : "#797979", border: !multipleProperties ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <LazyLoadImage src={!multipleProperties ? "/images/icon_property_white.png" : "/images/icon_property_grey.png"} style={{height: "20px"}} />
              <Typography.P comp={!multipleProperties ? "hl-button-selected" : "hl-button"} fontColor={!multipleProperties ? "#FFFFFF" : "#202020"}>
                One
              </Typography.P>
            </Stack>
          </button>
          <button
            onClick={() => {
              updateMultipleProperties(true)
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: multipleProperties ? "#66CFDC" : "#FFFFFF", color: multipleProperties ? "#FFFFFF" : "#797979", border: multipleProperties ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Stack direction="row" spacing={0.5}>
                <LazyLoadImage src={multipleProperties ? "/images/icon_property_white.png" : "/images/icon_property_grey.png"} style={{height: "20px"}} />
                <LazyLoadImage src={multipleProperties ? "/images/icon_property_white.png" : "/images/icon_property_grey.png"} style={{height: "20px"}} />
              </Stack>
              <Typography.P comp={multipleProperties ? "hl-button-selected" : "hl-button"} fontColor={multipleProperties ? "#FFFFFF" : "#202020"}>
                Multiple
              </Typography.P>
            </Stack>
          </button>
        </Stack>

        {properties.map((p, i) => {
          if (!multipleProperties && i > 0) {
            return
          }
          return (
            <div style={{ marginBlock: "40px" }} key={`property-detail-${i}`}>
              <Stack direction="row" justifyContent="space-between" className={styles.propertyHeader}>
                <Typography.P comp="medium-bold" fontColor="#FFFFFF">
                  {multipleProperties ? "Property #" + (i + 1) : "Property"}
                </Typography.P>
                {i >= 2 && <LazyLoadImage src={"/images/icon_remove.png"} style={{ height: "50px", cursor: "pointer", color: "#FCB3CD" }} onClick={() => removeProperties(i)} />}
              </Stack>
              <div style={{ backgroundColor: "#EEF4F5", padding: "15px 10px 20px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px" }}>
                  <button onClick={() => updatePropertyPurpose("OWNER_OCCUPIED", i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: p.purpose === "OWNER_OCCUPIED" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                    <Typography.P comp={p.purpose === "OWNER_OCCUPIED" ? "hl-button-selected" : "hl-button"} fontColor="#202020">
                      Home
                    </Typography.P>
                  </button>
                  <button onClick={() => updatePropertyPurpose("INVESTMENT", i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: p.purpose === "INVESTMENT" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                    <Typography.P comp={p.purpose === "INVESTMENT" ? "hl-button-selected" : "hl-button"} fontColor="#202020">
                      Investment
                    </Typography.P>
                  </button>
                </Stack>

                <Typography.P comp="hl-label" style={{ marginTop: "15px", marginBottom: "10px" }}>
                  Address
                </Typography.P>
                {p.addressEnteredManually ? (
                  <div>
                    {addressField("Unit Number", false, propertyAddresses[i]["Unit Number Error"], i)}
                    {addressField("Street Number", true, propertyAddresses[i]["Street Number Error"], i)}
                    {addressField("Street Name", true, propertyAddresses[i]["Street Name Error"], i)}
                    {addressField("Street Type", true, propertyAddresses[i]["Street Type Error"], i)}
                    {addressField("Suburb", true, propertyAddresses[i]["Suburb Error"], i)}
                    {addressField("State", true, propertyAddresses[i]["State Error"] || propertyAddresses[i]["State Invalid Error"], i)}
                    {propertyAddresses[i]["State Invalid Error"] ? (
                      <Typography.P comp="small-bold" fontColor="#F9689C">
                        {validStates.slice(0, validStates.length - 2).join(", ") + " or " + validStates[validStates.length - 1]}
                      </Typography.P>
                    ) : null}
                    {addressField("Postcode", true, propertyAddresses[i]["Postcode Error"] || propertyAddresses[i]["Postcode Invalid Error"], i)}
                    {propertyAddresses[i]["Postcode Invalid Error"] ? (
                      <Typography.P comp="small-bold" fontColor="#F9689C">
                        {"Enter a 4 digit postcode"}
                      </Typography.P>
                    ) : null}
                    <Stack justifyContent="center" alignItems="center" style={{ height: "60px", borderTop: "1px solid #EEF4F5", cursor: "pointer", backgroundColor: "#FFFFFF" }} onClick={() => updateAddressEnteredManually(false, i)}>
                      <Typography.P comp="medium-bold" fontColor="#4D4D4D">
                        Use address search
                      </Typography.P>
                    </Stack>
                  </div>
                ) : (
                  <div>
                    <div style={{ position: "relative", backgroundColor: "#FFFFFF" }}>
                      <img src={"/images/location_on_FILL0_wght400_GRAD0_opsz48.svg"} style={{ position: "absolute", top: "20px", left: "5px", height: "20px" }} />
                      <div className={styles.linearOpacityDiv} />
                      <TextField
                        key={i}
                        style={{ width: "100%", outline: p.addressError || p.addressDuplicateError ? "3px solid #F9689C" : "none" }}
                        variant="standard"
                        autoComplete="off"
                        value={p.address1 + (p.address2 ? ", " + p.address2 : "")}
                        // inputRef={loanAmountRef}
                        InputProps={{
                          disableUnderline: true,
                          style: classes.inputField,
                          // className: styles.inputField,
                        }}
                        onChange={e => updatePropertyAddress(e.target.value, "", i)}
                      />
                    </div>
                    {p.addressDuplicateError ? (
                      <Typography.P comp="small-bold" fontColor="#F9689C">
                        Duplicate address
                      </Typography.P>
                    ) : null}
                    {currentIndex === i && inputPropertyAddress.length > 0 ? (
                      <div style={{ borderTop: "1px solid #EEF4F5", backgroundColor: "#FFFFFF" }}>
                        {autocompleteResults &&
                          autocompleteResults.data.results.map((result, i) => {
                            return (
                              <Stack key={i} className={styles.autocomplete} justifyContent="center" alignItems="center" onClick={() => autocompleteOnClick(result)}>
                                <Typography.P comp="medium-tight" fontColor="#797979" style={{ width: "100%" }}>
                                  {result.address_line1}
                                </Typography.P>
                                <Typography.P comp="medium-tight" fontColor="#797979" style={{ width: "100%" }}>
                                  {result.address_line2.replace(", Australia", "")}
                                </Typography.P>
                              </Stack>
                            )
                          })}
                        <Stack justifyContent="center" alignItems="center" style={{ height: "60px", borderTop: "1px solid #EEF4F5", cursor: "pointer", marginTop: autocompleteResults && autocompleteResults.data.results.length > 0 ? "20px" : "0px" }} onClick={() => updateAddressEnteredManually(true, i)}>
                          <Typography.P comp="hl-medium" fontColor="#4D4D4D">
                            Can't find? Enter manually
                          </Typography.P>
                        </Stack>
                      </div>
                    ) : null}
                  </div>
                )}

                <Typography.P comp="hl-label" style={{ marginTop: "15px", marginBottom: "10px" }}>
                  Estimated property value
                </Typography.P>
                <div style={{ position: "relative", backgroundColor: "#FFFFFF" }}>
                  <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", left: "10px" }}>
                    $
                  </Typography.P>
                  <div className={styles.linearOpacityDiv} />
                  <TextField
                    style={{ width: "100%", outline: p.valueError ? "3px solid #F9689C" : "none" }}
                    defaultValue={p.value}
                    variant="standard"
                    autoComplete="off"
                    // inputRef={loanAmountRef}
                    InputProps={{
                      disableUnderline: true,
                      style: classes.inputField,
                      // className: styles.inputField,
                    }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*",
                    }}
                    onFocus={e => setTimeout(() => {
                      const l = e.target.value.length
                      const start = e.target.selectionStart || 0
                      const end = Math.max(start, e.target.selectionEnd || 0)
                      const v = Number(e.target.value.replace(/\D+/g, ''))
                      e.target.value = v === 0 ? "": v.toString()
                      e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                      e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                    }, 0)}
                    onChange={(e) => {
                      const l = e.target.value.length
                      const start = e.target.selectionStart || 0
                      const end = Math.max(start, e.target.selectionEnd || 0)
                      const v = Number(e.target.value.replace(/\D+/g, ''))
                      e.target.value = v === 0 ? "": v.toString()
                      e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                      e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter" || e.key === "Escape") {
                        (e.target as EventTarget & HTMLDivElement).blur()
                      }
                    }}
                    onBlur={e => {
                      formatPropertyValue(e)
                      updatePropertyValue(e.target.value, i)
                    }}
                  />
                </div>
                {p.valueError ? (
                  <Typography.P comp="small-bold" fontColor="#F9689C">
                    {getPropertyValueError(p.value)}
                  </Typography.P>
                ) : null}
              </div>
            </div>
          )
        })}
        {multipleProperties && (
          <Clickable.Text
            comp={3}
            disabled={false}
            className="survey-button"
            style={{ height: "60px", marginTop: "30px", backgroundColor: "#EEF4F5", color: "#202020" }}
            onClick={() => {
              setProperties(old => [...old, generatePropertyObject("INVESTMENT")])
            }}
          >
            + Add property
          </Clickable.Text>
        )}

        <Clickable.Text
          comp={3}
          className="hl-survey-button"
          style={{ marginTop: "50px" }}
          onClick={() => {
            if (validateInputs()) {
              const jsonInput = cleanInputs()
              DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_SUBMITTED, { surveyId: store.getState().survey.surveyId, ...jsonInput })
              navigate("/home-loans/select-home-loan-provider", {
                state: {
                  progress: progress + 1,
                  jsonInput: jsonInput,
                },
              })
            }
          }}
        >
          Continue
        </Clickable.Text>
      </div>
    </div>
  )
}

export default AboutYourProperty
