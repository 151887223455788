import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Typography } from "components/typography"
import ProgressBar from "components/survey/progress-bar"
import ProvidersListView from "components/survey/providers-list-view"
import HelpDialog from "components/help-dialog"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as Clickable from "components/clickable"
import { TextField } from "@mui/material"
import { Stack } from "@mui/material"
import { providerImage } from "components/survey/utils"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import * as styles from "./style.module.scss"
import dayjs, { Dayjs } from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"

const useStyles = () => ({
  inputField: {
    width: "100%",
    height: "60px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: "20px",
    color: "#202020",
    paddingLeft: "30px",
    paddingRight: "10px",
  },
  rateInputField: {
    width: "100%",
    height: "60px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: "20px",
    color: "#202020",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  dateInputField: {
    width: "100%",
    height: "60px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: "20px",
    color: "#202020",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
})

const SelectHomeLoan = (props: any) => {
  const [rateLowerLimit, setRateLowerLimit] = useState(1)
  const [rateUpperLimit, setRateUpperLimit] = useState(15)
  const store = useStore()
  const classes = useStyles()
  const state: any = store.getState()
  const progress = props.location.state.progress
  const jsonInput: any[] = props.location.state.jsonInput

  const [multipleProvider, setMultipleProvider] = useState(false)
  const [open, setOpen] = useState<{[key: number]: boolean}>({})
  const [providers, setProviders] = useState<{[key: string]: any}>({})
  const [currentIndex, setCurrentIndex] = useState(0)

  const [rbaRates, setRbaRates] = useState<{[key: number]: any}>({})
  const [splitLoan, setSplitLoan] = useState<{[key: number]: any}>({})
  const [offsetAccounts, setOffsetAccounts] = useState<{[key: number]: any}>({})
  const [loans, setLoans] = useState<{[key: number]: any}>({})
  const today = new Date()

  const months = [
    {
      "1": "Jan",
      "2": "Feb",
      "3": "Mar",
    },
    {
      "4": "Apr",
      "5": "May",
      "6": "Jun",
    },
    {
      "7": "Jul",
      "8": "Aug",
      "9": "Sep",
    },
    {
      "10": "Oct",
      "11": "Nov",
      "12": "Dec",
    },
  ]

//  console.log(jsonInput)

  const generateLoanObject = () => {
    return {
      value: "",
      repaymentType: "PRINCIPAL_AND_INTEREST",
      rateType: "VARIABLE",
      rate: null,
      calendarIsOpen: false,
      calendarYear: today.getFullYear(),
      expiryDate: null,
      knowRevertRate: true,
      knowRevertRateErrorRevertTo: null,
      revertRate: null,
      valueError: false,
      totalValueError: false,
      rateError: false,
      expiryDateError: false,
      revertRateError: false,
    }
  }

  const cleanValue = (value: string) => {
    return Number(value.replaceAll(",", ""))
  }

  useEffect(() => {
    const newLoans: {[key: string | number]: any} = {}
    jsonInput.map((x, i) => {
      newLoans[i] = [generateLoanObject(), generateLoanObject()]
    })
    setLoans(newLoans)

    API.getHLParameter((responseJson: any) => {
      if (responseJson) {
        const rbaRates = { OWNER_OCCUPIED: responseJson["ownerOccupied"], INVESTMENT: responseJson["investment"] }
        setRbaRates(rbaRates)

        setRateLowerLimit(responseJson["rateLowerLimit"])
        setRateUpperLimit(responseJson["rateUpperLimit"])
      }
    })
  }, [])

  const updateMultipleProvider = (value: boolean) => {
    setMultipleProvider(value)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROVIDER_SELECTED, { surveyId: state.survey.surveyId, multipleProvider: value })
  }

  const updateOpen = (index: number) => {
    const newOpen = { ...open }

    Object.keys(newOpen).map(k => {
      const kn = parseInt(k)
      if (kn === index) {
        newOpen[kn] = !newOpen[kn]
      } else {
        newOpen[kn] = false
      }
    })

    if (!(index in newOpen)) {
      newOpen[index] = true
    }

    setCurrentIndex(index)
    setOpen(newOpen)
  }

  const didSelectLoanProvider = (value: any) => {
    // DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_PROVIDER_SELECTED, { id: value.id, productCategory: "CREDIT_CARD" })
    // setLoanProvider(loanProvider === null ? value : null)
    const newProviders = { ...providers }
    newProviders[currentIndex] = value
    setProviders(newProviders)
    const newOpen = { ...open }
    newOpen[currentIndex] = false
    setOpen(newOpen)

    const dataCollectorProviders: {[key: string]: any} = {}

    if (!multipleProvider) {
      jsonInput.map((x, i) => {
        x.provider = { id: value.id, name: value.name }
        dataCollectorProviders["P" + (i + 1)] = x.provider.id
      })
      DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROVIDER_SUBMITTED, { surveyId: state.survey.surveyId, ...dataCollectorProviders })
      navigate("/home-loans/about-your-loan", {
        state: {
          jsonInput: jsonInput,
          progress: progress + 1,
        },
      })
    } else {
      dataCollectorProviders["P" + (currentIndex + 1)] = value.id
      DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROVIDER_SELECTED, dataCollectorProviders)
    }
  }

  const selectHomeLoanProviderPage = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        {jsonInput.length > 1 ? (
          <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px", marginTop: "15px", marginBottom: "40px" }}>
            <button
              onClick={() => {
                updateMultipleProvider(false)
              }}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: !multipleProvider ? "#66CFDC" : "#FFFFFF", color: !multipleProvider ? "#FFFFFF" : "#797979", border: !multipleProvider ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography.P comp="medium-bold" fontColor={!multipleProvider ? "#FFFFFF" : "#202020"}>
                  One Provider
                </Typography.P>
              </Stack>
            </button>
            <button
              onClick={() => {
                updateMultipleProvider(true)
              }}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: multipleProvider ? "#66CFDC" : "#FFFFFF", color: multipleProvider ? "#FFFFFF" : "#797979", border: multipleProvider ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography.P comp="medium-bold" fontColor={multipleProvider ? "#FFFFFF" : "#202020"}>
                  Multiple
                </Typography.P>
              </Stack>
            </button>
          </Stack>
        ) : null}
        {multipleProvider ? (
          <div>
            <div style={{ borderBottom: "1px solid #A6A6A6", marginTop: "10px", marginBlock: "20px" }} />
            {jsonInput.map((p, i) => {
              return (
                <div>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ cursor: "pointer" }} onClick={() => updateOpen(i)}>
                    <Stack style={{ height: "40px" }}>
                      <Typography.P comp="small-bold">{p.address1}</Typography.P>
                      <Typography.P>{p.address2}</Typography.P>
                    </Stack>
                    {i in providers ? <LazyLoadImage src={providerImage(providers[i].id)} style={{height: "40px"}} /> : <ArrowForwardIosIcon />}
                  </Stack>
                  <div style={{ borderBottom: "1px solid #A6A6A6", marginBlock: "20px" }} />
                  {i in open && open[i] && (
                    <div>
                      <ProvidersListView list={state.main.hlProvider} isSelectYourBanks={false} selectedProvider={null} didSelectProviderCallback={didSelectLoanProvider} />
                      <div style={{ borderBottom: "1px solid #A6A6A6", marginBlock: "20px" }} />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <ProvidersListView list={state.main.hlProvider} isSelectYourBanks={false} selectedProvider={null} didSelectProviderCallback={didSelectLoanProvider} />
        )}
      </div>
    )
  }

  const formatValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/\D+/g, "")
    if (value === "") {
      e.target.value = ""
    } else {
      e.target.value = Number(value).toLocaleString()
    }
  }

  const setRateValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const vArr = e.target.value.replace(/[^\d\.]/g, "").split(".")
    const v = vArr.length === 1 ? vArr[0] : vArr[0] + "." + vArr[1]
    const l = e.target.value.length
    const start = e.target.selectionStart || 0
    const end = Math.max(start, e.target.selectionEnd || 0)
    e.target.value = v
    e.target.selectionStart = Math.max(0, start - l + v.length)
    e.target.selectionEnd = Math.max(0, end - l + v.length)
  }

  const formatRateValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    // if (e.target.value.replace(/[^\d.]/g, '') === "") {
    //   e.target.value = ""
    //   return
    // }

    let value = e.target.value.replace(/\./g, (val, index, str) => (index === str.indexOf(".") ? val : ""))

    value = value.replace(/[^\d.]/g, "")
    e.target.value = value
  }

  const formatExpiryDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = Number(e.target.value.replace(/\D+/g, "").slice(0, 6))
    const suffix = "MMYYYY".slice(value.toString().length)
    const preFormatValue = value.toString() + suffix
    const formatValue = [preFormatValue.slice(0, 2), "/", preFormatValue.slice(2, 6)].join("")
    e.target.value = formatValue
  }

  const updateLoanValue = (value: string, index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index][i].value = value
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateRepaymentType = (value: string, index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index][i].repaymentType = value
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateRateType = (value: string, index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index][i].rateType = value
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateRateValue = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>, index: number, i: number) => {
    let rateValue = Number(e.target.value)
    const newLoans = { ...loans }
    newLoans[index][i].rate = rateValue.toFixed(2)
    e.target.value = rateValue.toFixed(2)
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateCalendarYear = (year: number, index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index][i].calendarYear = year
    if (newLoans[index][i].expiryDate && year === today.getFullYear() && newLoans[index][i].expiryDate.getMonth() < today.getMonth()) {
      (newLoans[index][i].expiryDate as Date).setMonth(today.getMonth())
    }
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const getFixedExpiryDate = (value: Date | null) => {
    if (!value) {
      return null
    }
    let year = value.getFullYear()
    let month = value.getMonth()
    if (Number.isNaN(year)) {
      year = today.getFullYear()
    }
    if (Number.isNaN(month)) {
      month = today.getMonth()
    }
    if (year < today.getFullYear()) {
      year = today.getFullYear()
    }
    if (year === today.getFullYear() && month < today.getMonth()) {
      month = today.getMonth()
    }
    return new Date(year, month)
  }

  const updateExpiryDate = (value: Date | null, index: number, i: number) => {
    const newLoans: any = { ...loans }
    newLoans[index][i].expiryDate = getFixedExpiryDate(value)
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateExpiryDateFromCalendar = (month: string, index: number, i: number) => {
    const dateObj = new Date(loans[index][i].calendarYear + "-" + month)
    updateExpiryDate(dateObj, index, i)
    updateCalendarIsOpen(false, index, i)
  }

  const updateExpiryDateOnBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>, index: number, i: number) => {
    const valMonth = parseInt(e.currentTarget.value.slice(0,2))
    const valYear = parseInt(e.currentTarget.value.slice(3))
    if (Number.isNaN(valMonth) || Number.isNaN(valYear)) {
      updateExpiryDate(null, index, i)
    } else {
      const dateObj = new Date(valYear + "-" + valMonth)
      updateExpiryDate(dateObj, index, i)
    }
  }

  const updateKnowRevertRate = (value: boolean, index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index][i].knowRevertRate = value
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateRevertRateValue = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>, index: number, i: number) => {
    let rateValue = Number(e.target.value)
    const newLoans = { ...loans }
    newLoans[index][i].revertRate = rateValue.toFixed(2)
    e.target.value = rateValue.toFixed(2)
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateCalendarIsOpen = (value: boolean, index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index][i].calendarIsOpen = value
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const updateOffsetAccounts = (value: string, error: boolean, index: number) => {
    const newOffsetAccounts = { ...offsetAccounts }
    newOffsetAccounts[index] = { value: value, error: error }
    setOffsetAccounts(newOffsetAccounts)
    const dataCollectorOffsetAccounts: {[key: string]: any} = {}
    Object.entries(newOffsetAccounts).map(([k, v], i) => {
      dataCollectorOffsetAccounts["P" + (parseInt(k) + 1)] = v.value
    })
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_OFFSET_UPDATED, { surveyId: state.survey.surveyId, ...dataCollectorOffsetAccounts })
  }

  const updateSplitLoan = (value: boolean, index: number) => {
    const newSplitLoan = { ...splitLoan }
    newSplitLoan[index] = value
    setSplitLoan(newSplitLoan)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, splitLoan: newSplitLoan })
  }

  const removeLoan = (index: number, i: number) => {
    const newLoans = { ...loans }
    newLoans[index] = (loans[index] as any[]).filter((_, j) => i !== j)
    setLoans(newLoans)
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
  }

  const removeOffsetAccount = (index: number) => {
    const newOffsetAccounts = { ...offsetAccounts }
    delete newOffsetAccounts[index]
    setOffsetAccounts(newOffsetAccounts)
    const dataCollectorOffsetAccounts: {[key: string]: any} = {}
    Object.values(newOffsetAccounts).map((o, i) => {
      dataCollectorOffsetAccounts["P" + (i + 1)] = o
    })
    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_OFFSET_UPDATED, { surveyId: state.survey.surveyId, ...dataCollectorOffsetAccounts })
  }

  const SelectLoan = (index: number) => {
    return (
      <div style={{ marginTop: "40px" }}>
        <PropertySummary index={index} />
        <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px", marginTop: "40px", marginBottom: "30px" }}>
          <button
            onClick={() => {
              updateSplitLoan(false, index)
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: !splitLoan[index] ? "#66CFDC" : "#FFFFFF", color: !splitLoan[index] ? "#202020" : "#FFFFFF", border: !splitLoan[index] ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography.P comp={!splitLoan[index] ? "hl-button-selected" : "hl-button"} fontColor={!splitLoan[index] ? "#FFFFFF" : "#202020"}>
                Single Loan
              </Typography.P>
            </Stack>
          </button>
          <button
            onClick={() => {
              updateSplitLoan(true, index)
            }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: splitLoan[index] ? "#66CFDC" : "#FFFFFF", color: splitLoan[index] ? "#FFFFFF" : "#202020", border: splitLoan[index] ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
          >
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography.P comp={splitLoan[index] ? "hl-button-selected" : "hl-button"} fontColor={splitLoan[index] ? "#FFFFFF" : "#202020"}>
                Split Loan
              </Typography.P>
            </Stack>
          </button>
        </Stack>
        {(loans[index] as any[]).map((l, i) => {
          if (!splitLoan[index] && i > 0) {
            return
          }
          return (
            <div key={i} style={{ marginBlock: "40px" }}>
              <Stack direction="row" justifyContent="space-between" className={styles.loanHeader}>
                <Typography.P comp="medium-bold" fontColor="#FFFFFF">
                  {splitLoan[index] ? "Loan #" + (i + 1) : "Loan"}
                </Typography.P>
                <Stack direction="row" alignItems="center">
                  <Typography.P comp="hl-property-small" style={{ paddingRight: i >= 2 ? "0px" : "10px" }}>
                    {jsonInput[index].address1}
                  </Typography.P>
                  {i >= 2 && <LazyLoadImage src={"/images/icon_remove.png"} style={{ height: "50px", cursor: "pointer", color: "#FCB3CD" }} onClick={() => removeLoan(index, i)} />}
                </Stack>
              </Stack>
              <div className={styles.loanBody}>
                <Typography.P comp="hl-label" style={{ marginBottom: "15px" }}>
                  Current balance
                </Typography.P>
                <div style={{ position: "relative", backgroundColor: "#FFFFFF" }}>
                  <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", left: "10px" }}>
                    $
                  </Typography.P>
                  <TextField
                    style={{ width: "100%", outline: l.valueError || l.totalValueError ? "3px solid #F9689C" : "none" }}
                    defaultValue={l.value}
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: classes.inputField,
                      // className: styles.inputField,
                    }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*",
                      onKeyDown: e => {
                        if (e.key === "Enter" || e.key === "Escape") {
                          e.currentTarget.blur()
                        }
                      }
                    }}
                    onFocus={e => setTimeout(() => {
                      const l = e.target.value.length
                      const start = e.target.selectionStart || 0
                      const end = Math.max(start, e.target.selectionEnd || 0)
                      const v = Number(e.target.value.replace(/\D+/g, ''))
                      e.target.value = v === 0 ? "": v.toString()
                      e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                      e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                    }, 0)}
                    onChange={(e) => {
                      const l = e.target.value.length
                      const start = e.target.selectionStart || 0
                      const end = Math.max(start, e.target.selectionEnd || 0)
                      const v = Number(e.target.value.replace(/\D+/g, ''))
                      e.target.value = v === 0 ? "": v.toString()
                      e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                      e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                    }}
                    onBlur={e => {
                      formatValue(e)
                      updateLoanValue(e.target.value, index, i)
                    }}
                  />
                </div>
                {l.totalValueError ? (
                  <Typography.P comp="small-bold" fontColor="#F9689C">
                    Total loan can't exceed property value
                  </Typography.P>
                ) : l.valueError ? (
                  <Typography.P comp="small-bold" fontColor="#F9689C">
                    Loan amount can't be $0
                  </Typography.P>
                ) : null}

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                    Repaying
                  </Typography.P>
                  <HelpDialog buttonId="repaymentType" icon={"/images/icon_info.png"} info={<div>‘Principal & Interest’ repayments pay off interest and a portion of the loan amount, reducing the balance over time. ‘Interest Only’ repayments cover only the interest charges, leaving the principal untouched. Your bank can confirm the repayment type if you are unsure.</div>} />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px" }}>
                  <button onClick={() => updateRepaymentType("PRINCIPAL_AND_INTEREST", index, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.repaymentType === "PRINCIPAL_AND_INTEREST" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                    <Typography.P comp={l.repaymentType === "PRINCIPAL_AND_INTEREST" ? "hl-button-selected" : "hl-button"}>Principal & Interest</Typography.P>
                  </button>
                  <button onClick={() => updateRepaymentType("INTEREST_ONLY", index, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.repaymentType === "INTEREST_ONLY" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                    <Typography.P comp={l.repaymentType === "INTEREST_ONLY" ? "hl-button-selected" : "hl-button"}>Interest only</Typography.P>
                  </button>
                </Stack>

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                    Current rate
                  </Typography.P>
                  <HelpDialog buttonId="interestRate" icon={"/images/icon_info.png"} info={<div>Your current interest rate should be available within your banking app or on you latest loan statement. Your statement may not reflect the most recent interest rate changes. You can call your bank to confirm.</div>} />
                </Stack>
                <div style={{ position: "relative", backgroundColor: "#FFFFFF", outline: l.rate != null && (Number(l.rate) < rateLowerLimit || Number(l.rate) > rateUpperLimit) ? "3px solid #F9689C" : "none" }}>
                  <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", right: "15px" }}>
                    %
                  </Typography.P>
                  <TextField
                    style={{ width: "100%", outline: l.rateError ? "3px solid #F9689C" : "none" }}
                    placeholder="0.00"
                    defaultValue={l.rate ? l.rate : ""}
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: classes.rateInputField,
                      // className: styles.inputField,
                    }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*\.?[0-9]*",
                      onKeyDown: e => {
                        if (e.key === "Enter" || e.key === "Escape") {
                          e.currentTarget.blur()
                        }
                      }
                    }}
                    onChange={e => setRateValue(e)}
                    onBlur={e => {
                      updateRateValue(e, index, i)
                      formatRateValue(e, i)
                    }}
                  />
                </div>
                {l.rate != null && (Number(l.rate) < rateLowerLimit || Number(l.rate) > rateUpperLimit) ? (
                  <Typography.P comp="small-bold" fontColor="#F9689C" style={{ marginTop: "5px" }}>
                    Not a valid home loan rate
                  </Typography.P>
                ) : null}

                <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px", marginTop: "20px" }}>
                  <button onClick={() => updateRateType("VARIABLE", index, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.rateType === "VARIABLE" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                    <Typography.P comp={l.rateType === "VARIABLE" ? "hl-button-selected" : "hl-button"}>Variable</Typography.P>
                  </button>
                  <button onClick={() => updateRateType("FIXED", index, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.rateType === "FIXED" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                    <Typography.P comp={l.rateType === "FIXED" ? "hl-button-selected" : "hl-button"}>Fixed</Typography.P>
                  </button>
                </Stack>

                {l.rateType === "FIXED" && (
                  <div>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                        Fixed rate expiry date
                      </Typography.P>
                      <HelpDialog buttonId="fixedRate" icon={"/images/icon_info.png"} info={<div>Fixed rate loans usually last 1-5 years. To find your fixed rate expiry date, review your online banking app, or most recent statement. Otherwise, simply call your bank and ask.</div>} />
                    </Stack>
                    <div style={{ position: "relative", backgroundColor: "#FFFFFF", width: "100%" }}>
                      <CalendarMonthOutlinedIcon style={{ position: "absolute", top: "12.5px", right: "10px", height: "35px", width: "35px", cursor: "pointer", zIndex: "1" }} onClick={() => updateCalendarIsOpen(!l.calendarIsOpen, index, i)} />
                      {l.calendarIsOpen && (
                        <div style={{ position: "absolute", top: "0px", left: "0px", width: "320px", backgroundColor: "#FFFFFF", zIndex: "1", border: "1px solid #EEF4F5" }}>
                          <Stack direction="row" justifyContent="space-around" alignItems="center" style={{ height: "60px", borderBottom: "1px solid #EEF4F5" }}>
                            <ChevronLeftIcon style={{ cursor: l.calendarYear > today.getFullYear() ? "pointer" : "default", color: l.calendarYear > today.getFullYear() ? "#000000" : "D2D2D2" }} onClick={() => (l.calendarYear > today.getFullYear() ? updateCalendarYear(l.calendarYear - 1, index, i) : null)} />
                            <Typography.P comp="medium-bold">{l.calendarYear}</Typography.P>
                            <ChevronRightIcon style={{ cursor: "pointer", color: "#000000" }} onClick={() => updateCalendarYear(l.calendarYear + 1, index, i)} />
                          </Stack>
                          {months.map(monthsObj => {
                            return (
                              <Stack direction="row" justifyContent="space-around" alignItems="center" style={{ backgroundColor: "#FFFFFF" }}>
                                {Object.entries(monthsObj).map(([k, v], j) => {
                                  let className = styles.monthCell
                                  let isDisabled = false
                                  let currentMonth = false
                                  const expDate = l.expiryDate
                                  if (expDate instanceof Date) {
                                    currentMonth = (l.calendarYear === expDate.getFullYear())
                                    currentMonth = currentMonth && ((expDate.getMonth() + 1) === Number(k))
                                  }
                                  if (currentMonth) {
                                    className = styles.monthCellSelected
                                  } else if (l.calendarYear === today.getFullYear() && Number(k) <= today.getMonth()) {
                                    className = styles.monthCellDisabled
                                    isDisabled = true
                                  }
                                  return (
                                    <Stack justifyContent="center" alignItems="center" className={className} onClick={() => (!isDisabled ? updateExpiryDateFromCalendar(k, index, i) : null)}>
                                      {v}
                                    </Stack>
                                  )
                                })}
                              </Stack>
                            )
                          })}
                        </div>
                      )}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          format="MM/YYYY"
                          value={l.expiryDate ? dayjs(l.expiryDate) : null}
                          className={styles.datePicker}
                          disableOpenPicker
                          slots={{
                            openPickerIcon: CalendarMonthOutlinedIcon,
                          }}
                          slotProps={{
                            textField: {
                              onKeyDown: (e) => {
                                if (e.key === 'Enter' || e.key === "Escape") {
                                  e.currentTarget.blur()
                                }
                              },
                              onBlur: (e) => {
                                updateExpiryDateOnBlur(e, index, i)
                              },
                              onFocus: (e) => {
                                updateExpiryDate(null, index, i)
                              }
                            }
                          }}
                          sx={{
                            ".MuiInputBase-input": {
                              width: "100%",
                              fontFamily: "Arboria-Bold, sans-serif",
                              fontSize: "18px",
                              color: l.expiryDate ? "#000000" : "#A9A9A9",
                              border: "none",
                            },
                            "&.MuiTextField-root": {
                              width: "100%",
                              border: "none",
                              outline: l.expirydateError ? "3px solid #F9689C" : "none",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              visibility: "hidden",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </div>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                        Variable rate after fixed rate expiry
                      </Typography.P>
                      <HelpDialog buttonId="revertRate" icon={"/images/icon_info.png"} info={<div>Once a Fixed rate loan expires, a new interest rate (ie. ‘revert rate’) will apply to the loan. To find the ‘revert rate’, review your online banking app, your most recent loan statement, or alternatively give your bank a call and ask. Otherwise, select ‘I don’t know’ below and we’ll apply an average industry rate from the RBA website.</div>} />
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px" }}>
                      <button onClick={() => updateKnowRevertRate(true, index, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.knowRevertRate ? "#FFFFFF" : "#EEF4F5", border: l.knowRevertRateErrorRevertTo ? "2px solid #F9689C" : "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                        <Typography.P comp={l.knowRevertRate ? "hl-button-selected" : "hl-button"}>I know it</Typography.P>
                      </button>
                      <button onClick={() => updateKnowRevertRate(false, index, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: !l.knowRevertRate ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                        <Typography.P comp={!l.knowRevertRate ? "hl-button-selected" : "hl-button"}>I don't know it</Typography.P>
                      </button>
                    </Stack>

                    {l.knowRevertRate && (
                      <div>
                        <div style={{ position: "relative", backgroundColor: "#FFFFFF", marginTop: "20px", outline: l.revertRate != null && (Number(l.revertRate) < rateLowerLimit || Number(l.revertRate) > rateUpperLimit) ? "3px solid #F9689C" : "none" }}>
                          <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", right: "15px" }}>
                            %
                          </Typography.P>
                          <TextField
                            style={{ width: "100%", outline: l.revertRateError || l.knowRevertRateErrorRevertTo ? "3px solid #F9689C" : "none" }}
                            placeholder="0.00"
                            defaultValue={l.revertRate ? l.revertRate : ""}
                            variant="standard"
                            autoComplete="off"
                            InputProps={{
                              disableUnderline: true,
                              style: classes.rateInputField,
                              // className: styles.inputField,
                            }}
                            inputProps={{
                              inputMode: "decimal",
                              pattern: "[0-9]*",
                              onKeyDown: e => {
                                if (e.key === "Enter" || e.key === "Escape") {
                                  e.currentTarget.blur()
                                }
                              }
                            }}
                            onChange={e => setRateValue(e)}
                            onBlur={e => {
                              updateRevertRateValue(e, index, i)
                              formatRateValue(e, i)
                            }}
                          />
                        </div>
                        {l.knowRevertRateErrorRevertTo ? (
                          <Typography.P comp="small-bold" fontColor="#F9689C">
                            {"Use Loan #" + (l.knowRevertRateErrorRevertTo.loanIndex + 1) + " variable rate (" + l.knowRevertRateErrorRevertTo.rate + "%)"}
                          </Typography.P>
                        ) : null}
                        {l.revertRate != null && (Number(l.revertRate) < rateLowerLimit || Number(l.revertRate) > rateUpperLimit) ? (
                          <Typography.P comp="small-bold" fontColor="#F9689C" style={{ marginTop: "5px" }}>
                            Not a valid home loan rate
                          </Typography.P>
                        ) : null}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        })}
        {index in offsetAccounts ? (
          <div>
            <Stack direction="row" justifyContent="space-between" className={styles.loanHeader}>
              <Typography.P comp="medium-bold" fontColor="#FFFFFF">
                Offset account
              </Typography.P>
              <LazyLoadImage src={"/images/icon_remove.png"} style={{ height: "50px", cursor: "pointer", color: "#FCB3CD" }} onClick={() => removeOffsetAccount(index)} />
            </Stack>
            <div className={styles.loanBody}>
              <Stack direction="row" justifyContent="space-between">
                <Typography.P comp="hl-label">Current balance</Typography.P>
                <HelpDialog buttonId="loanValue" icon={"/images/icon_info.png"} info={<div>A mortgage offset account is a linked bank account that reduces the interest on a home loan. The balance in the account is subtracted from the mortgage balance, resulting in lower interest payments. If you have multiple offset accounts, enter the total as the ‘current balance’.</div>} />
              </Stack>
              <div style={{ position: "relative", backgroundColor: "#FFFFFF", marginTop: "15px", outline: offsetAccounts[index].error ? "3px solid #F9689C" : "none" }}>
                <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", left: "10px" }}>
                  $
                </Typography.P>
                <TextField
                  style={{ width: "100%" }}
                  defaultValue={offsetAccounts[index].value}
                  variant="standard"
                  autoComplete="off"
                  InputProps={{
                    disableUnderline: true,
                    style: classes.inputField,
                    // className: styles.inputField,
                  }}
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*",
                    onKeyDown: e => {
                      if (e.key === "Enter" || e.key === "Escape") {
                        e.currentTarget.blur()
                      }
                    }
                  }}
                  onFocus={e => setTimeout(() => {
                    const l = e.target.value.length
                    const start = e.target.selectionStart || 0
                    const end = Math.max(start, e.target.selectionEnd || 0)
                    const v = Number(e.target.value.replace(/\D+/g, ''))
                    e.target.value = v === 0 ? "": v.toString()
                    e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                    e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                  }, 0)}
                  onChange={(e) => {
                    const l = e.target.value.length
                    const start = e.target.selectionStart || 0
                    const end = Math.max(start, e.target.selectionEnd || 0)
                    const v = Number(e.target.value.replace(/\D+/g, ''))
                    e.target.value = v === 0 ? "": v.toString()
                    e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                    e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                  }}
                  onBlur={e => {
                    formatValue(e)
                    updateOffsetAccounts(e.target.value, false, index)
                  }}
                />
              </div>
              {offsetAccounts[index].error && cleanValue(offsetAccounts[index].value) > 0 ? (
                <Typography.P comp="small-bold" fontColor="#F9689C">
                  Offset balance can't exceed loan amount
                </Typography.P>
              ) : offsetAccounts[index].error ? (
                <Typography.P comp="small-bold" fontColor="#F9689C">
                  Offset balance can't be $0
                </Typography.P>
              ) : null}
            </div>
          </div>
        ) : null}
        {splitLoan[index] && (
          <Clickable.Text
            comp={3}
            disabled={false}
            className="survey-button"
            style={{ marginBlock: "20px", backgroundColor: "#EEF4F5", color: "#202020" }}
            onClick={() => {
              const newLoans = { ...loans }
              newLoans[index].push(generateLoanObject())
              setLoans(newLoans)
              DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { surveyId: state.survey.surveyId, loans: newLoans })
            }}
          >
            + Add loan
          </Clickable.Text>
        )}
        {!(index in offsetAccounts) ? (
          <Clickable.Text
            comp={3}
            disabled={false}
            className="survey-button"
            style={{ marginTop: "20px", backgroundColor: "#EEF4F5", color: "#202020" }}
            onClick={() => {
              updateOffsetAccounts("", false, index)
            }}
          >
            + Add offset account
          </Clickable.Text>
        ) : null}
      </div>
    )
  }

  const selectLoans = () => {
    return (
      <div style={{ marginTop: "40px" }}>
        {Object.keys(loans).length > 0 &&
          jsonInput.map((x, i) => {
            return <div>{SelectLoan(i)}</div>
          })}
      </div>
    )
  }

  const PropertySummary = ({ index }: { index: number }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: "20px" }}>
        <Stack spacing={-0.25}>
          <Typography.P comp="medium-bold" fontColor="#202020">
            {jsonInput[index].address1}
          </Typography.P>
          <Typography.P comp="medium-tight" fontColor="#202020">
            {jsonInput[index].address2}
          </Typography.P>
        </Stack>
        {jsonInput && <LazyLoadImage src={providerImage(jsonInput[index].provider.id)} alt={jsonInput[index].provider.name} title={jsonInput[index].provider.name} style={{ height: "38px", width: "56px" }} />}
      </Stack>
    )
  }

  const checkDisabled = () => {
    if (progress === 1) {
      if (jsonInput.length === providers.length) {
        return true
      }
      return false
    } else if (progress === 2) {
      return false
    }
  }

  const validateInputs = () => {
    let noError = true
    const newLoans: {[key: string]: any} = JSON.parse(JSON.stringify(loans))
    const newOffsetAccounts = { ...offsetAccounts }

    Object.values(newLoans).map((p, i) => {
      const length = splitLoan[i] ? p.length : 1
      const propertyValue = jsonInput[i].value
      let totalPropertyLoanValue = 0

      for (let j = 0; j < length; j++) {
        if (!p[j].value || cleanValue(p[j].value) === 0) {
          p[j].valueError = true
          noError = false
        } else {
          p[j].valueError = false
          totalPropertyLoanValue += cleanValue(p[j].value)
        }
        if (!p[j].rate || p[j].rate < rateLowerLimit || p[j].rate > rateUpperLimit) {
          p[j].rateError = true
          noError = false
        } else {
          p[j].rateError = false
        }
        const expDate: Date = new Date(p[j].expiryDate)
        if (p[j].rateType === "FIXED" && (!expDate
          || Number.isNaN(expDate.getFullYear()) || Number.isNaN(expDate.getMonth())
          || expDate.getFullYear() < today.getFullYear() || (expDate.getFullYear() === today.getFullYear()
          && expDate.getMonth() < today.getMonth()))
        ) {
          p[j].expirydateError = true
          noError = false
        } else {
          p[j].expirydateError = false
        }
        if (p[j].rateType === "FIXED" && p[j].knowRevertRate && (!p[j].revertRate || p[j].revertRate < rateLowerLimit || p[j].revertRate > rateUpperLimit)) {
          p[j].revertRateError = true
          noError = false
        } else {
          p[j].revertRateError = false
        }
      }

      // find best variable rate within property for each repayment type
      const bestVariableRate: any = {}
      for (let j = 0; j < length; j++) {
        if (p[j].rateType === "VARIABLE") {
          if (!(p[j].repaymentType in bestVariableRate)) {
            bestVariableRate[p[j].repaymentType] = { rate: null, loanIndex: null }
          }
          if (!bestVariableRate[p[j].repaymentType].rate || p[j].rate < bestVariableRate[p[j].repaymentType].rate) {
            bestVariableRate[p[j].repaymentType].rate = p[j].rate
            bestVariableRate[p[j].repaymentType].loanIndex = j
          }
        }
      }

      for (let j = 0; j < length; j++) {
        if (p[j].rateType === "FIXED" && !p[j].knowRevertRate && p[j].repaymentType in bestVariableRate) {
          p[j].knowRevertRate = true
          p[j].knowRevertRateErrorRevertTo = bestVariableRate[p[j].repaymentType]
          noError = false
        } else {
          p[j].knowRevertRateErrorRevertTo = null
        }
      }

      if (totalPropertyLoanValue > propertyValue) {
        for (let j = 0; j < length; j++) {
          p[j].totalValueError = true
        }
        noError = false
      } else {
        for (let j = 0; j < length; j++) {
          p[j].totalValueError = false
        }
      }

      if (i in newOffsetAccounts) {
        if (newOffsetAccounts[i].value.length === 0) {
          newOffsetAccounts[i].error = true
          noError = false
        } else if (cleanValue(newOffsetAccounts[i].value) > totalPropertyLoanValue) {
          newOffsetAccounts[i].error = true
          noError = false
        } else {
          newOffsetAccounts[i].error = false
        }
      }
    })
    setLoans(newLoans)
    setOffsetAccounts(newOffsetAccounts)

    return noError
  }

  const cleanInputs = () => {
    jsonInput.map((p, i) => {
      jsonInput[i]["loans"] = loans[i]
      if (!splitLoan[i]) {
        jsonInput[i]["loans"] = loans[i].slice(0, 1)
      }
    })
    jsonInput.map((p, i) => {
      if (i in offsetAccounts) {
        p.offset = cleanValue(offsetAccounts[i].value)
      } else {
        p.offset = null
      }
      (p.loans as any[]).map((l, j) => {
        l.value = cleanValue(l.value)
        l.rate = Number(l.rate) / 100

        if (l.rateType === "FIXED") {
          if (l.knowRevertRate) {
            l.revertRate = Number(l.revertRate) / 100
          } else {
            l.revertRate = rbaRates[p.purpose]
          }
          let expDate = (l.expiryDate as Date)
          if (typeof expDate === 'string') {
            expDate = new Date(expDate)
          }
          const offset = expDate.getTimezoneOffset()
          expDate = new Date(expDate.getTime() - (offset*60*1000))
          l.expiryDate = expDate.toISOString().split('T')[0]
        } else {
          l.revertRate = null
        }

        delete l.calendarIsOpen
        delete l.calendarYear
        delete l.expiryDateError
        delete l.knowRevertRateErrorRevertTo
        delete l.rateError
        delete l.revertRateError
        delete l.totalValueError
        delete l.valueError
      })
    })
    const newJsonInput = { properties: jsonInput }

    return newJsonInput
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey">{progress === 1 ? "Your home loan provider" : "About your home loan"}</Typography.H3>

        <ProgressBar maxSteps={4} progress={progress} />

        {progress === 1 && selectHomeLoanProviderPage()}
        {progress === 2 && selectLoans()}
        {/* {progress === 2 && <SelectLoanNumber />} */}
        {/* {progress === 3 && <LoanAccount />} */}
        {/* {progress === 4 && <LoanSummary />} */}
        {/* {progress === 4 && selectLoanTypePage()} */}
        {/* {progress === 5 && selectAmountPage()} */}
        {/* {progress === 6 && selectVariableRatePage()} */}
        {/* {progress === 7 && isSelectExactRate && selectExactRatePage()} */}
        {/* {progress === 7 && isSelectApproximateRate && selectApproximateRatePage()} */}

        {(progress === 1 && multipleProvider) || progress === 2 ? (
          <Clickable.Text
            comp={3}
            disabled={checkDisabled()}
            style={{
              height: "60px",
              width: "100%",
              marginTop: "60px",
            }}
            onClick={() => {
              if (progress === 1) {
                jsonInput.map((p, i) => {
                  jsonInput[i]["provider"] = { id: providers[i].id, name: providers[i].name }
                })
                const dataCollectorProviders: {[key: string]: any} = {}
                jsonInput.map((x, i) => {
                  dataCollectorProviders["P" + (i + 1)] = x.provider.id
                })
                DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROVIDER_SUBMITTED, { surveyId: state.survey.surveyId, ...dataCollectorProviders })
                navigate("/home-loans/about-your-loan", {
                  state: {
                    jsonInput: jsonInput,
                    progress: progress + 1,
                  },
                })
              } else if (progress === 2) {
                if (validateInputs()) {
                  const newJsonInput = cleanInputs()
                  API.updateHLSurveyInput({ surveyId: state.survey.surveyId, jsonInput: newJsonInput.properties }, () => {
                    const dataCollecotrLoans: {[key: string]: any} = {}
                    newJsonInput.properties.map((p, i) => {
                      const propertyKey = "P" + (i + 1)
                      dataCollecotrLoans[propertyKey] = {}
                      return (p.loans as any[]).map((l, j) => {
                        dataCollecotrLoans[propertyKey]["L" + (j + 1)] = l
                      })
                    })
                    DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_SUBMITTED, { surveyId: state.survey.surveyId, ...dataCollecotrLoans })
                    navigate("/home-loans/reviewing-market", {
                      state: {
                        jsonInput: newJsonInput,
                        progress: progress + 1,
                      },
                    })
                  })
                }
              }
              // let result = loans
              // if (!splitLoan) {
              //   result = loans.slice(0, 1)
              // }
              // jsonInput[currentIndex]["loans"] = result
              // if (currentIndex < jsonInput.length - 1) {
              //   navigate("/home-loans/select-home-loan-provider", {
              //     state: {
              //       progress: progress - 1,
              //       currentIndex: currentIndex + 1,
              //       jsonInput: jsonInput,
              //     }
              //   })
              // } else {
              //   navigate("/home-loans/offers", {
              //     state: {
              //       progress: progress + 1,
              //       jsonInput: jsonInput,
              //     },
              //   })
              // }
            }}
          >
            Continue
          </Clickable.Text>
        ) : null}
      </div>
    </div>
  )
}

export default SelectHomeLoan
