import React from "react"
import { Router } from "@reach/router"
import HomeLoansPage from "components/content/home-loans-page"
// import HomeLoansLandingPage from "components/content/home-loans-external-landing-page"
import Layout from "components/layout"
import Instructions from "components/survey/instructions"
import AboutYourProperty from "components/hl-survey/about-your-property"
import SelectHomeLoan from "components/hl-survey/select-home-loan"
import YourCurrentPrice from "components/hl-survey/your-current-price"
import Offers from "components/hl-survey/offers"
import HowItWorks from "components/hl-survey/how-it-works"
import ReviewingMarket from "components/hl-survey/reviewing-market"
import ThankYou from "components/hl-survey/thank-you"
import PrivateRoute from "components/user/private-route"
import RefinanceRedirect from "components/hl-survey/refinance"
import SignUp from "components/user/sign-up"
import { apis as API, DataCollector, DataCollectorAction } from "@sog/sdk"
import { useStore } from "react-redux"
import SignIn from "components/user/sign-in"
import VerifyOtp from "components/user/verify-otp"
import Notifying from "components/hl-survey/notifying"
import WhatHeppensNext from "components/hl-survey/what-happens-next"
import ReviewInProgress from "components/hl-survey/review-in-progress"
import UpdatePropertyDetails from "components/hl-survey/update-property-details"

const HLSurveyBase = ({
  component: Component,
  loginState = 'any',
  redirectTo = '/',
  ...rest
}: {
  component: (props: any) => React.ReactNode,
  loginState?: 'any' | 'logged-in' | 'logged-out',
  redirectTo?: string,
  [key: string]: any
}) => {
  if (loginState === 'any') {
    return <Component {...rest} />
  }
  return <PrivateRoute
    redirectTo={redirectTo}
    component={Component}
    loggedOutOnly={loginState === 'logged-out'}
    {...rest}
  />
}

export default function HLSurvey(props: any) {
  const store = useStore<any>()

  return (
    <Layout title="Home Loan Comparison | Stay or Go" description="Compare home loan offers from all the major banks and popular brands." image="/video/StayOrGo_Animation_01.jpf" urlSlug="home-loans">
      <Router basepath="/home-loans" primary={false}>
        <HLSurveyBase path="/" component={HomeLoansPage} />
        {/* <HLSurveyBase path="/landing" component={HomeLoansLandingPage} /> */}
        <HLSurveyBase path="/instructions" component={Instructions} type={"hl"} />
        <HLSurveyBase path="/about-your-property" component={AboutYourProperty} />
        <HLSurveyBase path="/select-home-loan-provider" component={SelectHomeLoan} />
        <HLSurveyBase path="/about-your-loan" component={SelectHomeLoan} />
        {/* <HLSurveyBase path="/loan-summary" component={SelectHomeLoan} /> */}
        {/* <HLSurveyBase path="/select-amount" component={SelectHomeLoan} /> */}
        {/* <HLSurveyBase path="/select-rate" component={SelectHomeLoan} /> */}
        {/* <HLSurveyBase path="/select-exact-rate" component={SelectHomeLoan} /> */}
        {/* <HLSurveyBase path="/select-approximate-rate" component={SelectHomeLoan} /> */}
        {/* <HLSurveyBase path="/your-current-price" component={YourCurrentPrice} /> */}
        <HLSurveyBase path="/your-deal-vs-market" component={Offers} />
        <HLSurveyBase path="/update-property-details" component={UpdatePropertyDetails} />
        <HLSurveyBase path="/review-in-progress" component={ReviewInProgress} />
        <HLSurveyBase path="/notifying" component={Notifying} />
        <HLSurveyBase path="/what-happens-next" component={WhatHeppensNext} />
        <HLSurveyBase path="/offers-summary" component={Offers} />
        <HLSurveyBase path="/offer-details" component={Offers} />
        <HLSurveyBase path="/reviewing-market" component={ReviewingMarket} />
        <HLSurveyBase path="/offers" component={Offers} />
        {/* <HLSurveyBase path="/how-it-works" component={HowItWorks} /> */}
        {/* sign-in base */}
        <HLSurveyBase path="/sign-in" component={(p) => <SignIn
          signupPagePath="/home-loans/create-account/"
          onSuccessPagePath="/home-loans/sign-in/verify-email"
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        {/* sign-in verify email */}
        <HLSurveyBase path="/sign-in/verify-email" component={(p) => <VerifyOtp
          context="hl_survey"
          surveyId={store.getState().survey.surveyId}
          onSuccessPagePath="/home-loans/offers-summary"
          baseRedirectPagePath="/home-loans/sign-in"
          previousPagePath="/home-loans/sign-in"
          {...p}
        />} loginState={'logged-out'} redirectTo="/user/dashboard" />
        {/* sign-up base */}
        <HLSurveyBase path="/create-account" component={(p) => <SignUp
          signinPagePath="/home-loans/sign-in"
          onSuccessPagePath="/home-loans/create-account/verify-email"
          onFormSubmitAction={(data) => {
            API.updateHLSurveyRegistrations({
              survey_id: store.getState().survey.surveyId,
              firstName: data.user_info.first_name,
              lastName: data.user_info.last_name,
              email: data.email,
              mobile_number: data.mobile_number
            })
          }}
          onSuccessCallback={(data, responseJson) => {
            DataCollector.getInstance().addAction(DataCollectorAction.CREATE_ACCOUNT, {
              surveyId: store.getState().survey.surveyId,
              firstName: data.user_info.first_name,
              lastName: data.user_info.last_name,
              email: data.email,
              mobile: data.mobile_number,
              context: "hl_survey",
            })
          }}
          {...p}
        />} loginState={'logged-out'} />
        {/* sign-up verify email */}
        <HLSurveyBase path="/create-account/verify-email" component={(p) => <VerifyOtp
          context="hl_survey"
          surveyId={store.getState().survey.surveyId}
          isSignUp
          onSuccessPagePath="/home-loans/create-account/verify-mobile"
          baseRedirectPagePath="/home-loans/create-account"
          previousPagePath="/home-loans/create-account"
          {...p}
        />} loginState={'logged-out'} />
        {/* sign-up verify mobile */}
        <HLSurveyBase path="/create-account/verify-mobile" component={(p) => <VerifyOtp
          context="hl_survey"
          surveyId={store.getState().survey.surveyId}
          isSignUp
          isMobileVerificationPage
          onSuccessPagePath="/home-loans/offers-summary"
          baseRedirectPagePath="/home-loans/create-account"
          previousPagePath="/home-loans/create-account/verify-email"
          {...p}
        />} loginState={'logged-out'} isMobileState />
        <HLSurveyBase path="/thank-you" component={ThankYou} />
        <HLSurveyBase path="/refinance" component={RefinanceRedirect} />
      </Router>
    </Layout>
  )
}
