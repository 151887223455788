import React from "react"
import { navigate } from "gatsby-link"
import { Typography } from "components/typography"
import * as Clickable from "components/clickable"

const WhatHappensNext = (props: any) => {
  const providerName = props.location.state.providerName
  const expireDate = props.location.state.expireDate

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H2 comp="hl-survey" style={{ textAlign: "center" }}>
          What happens next?
        </Typography.H2>
        <Typography.P comp="medium-black">
          {providerName} have received your request and have until {expireDate} to response.
        </Typography.P>
        <Typography.P comp="medium-black" style={{ marginTop: "20px" }}>
          We'll notify you once an outcome is available.
        </Typography.P>
        <Clickable.Text
          comp={3}
          className="survey-button"
          style={{ marginTop: "55px", height: "60px" }}
          onClick={() => {
            navigate("/")
          }}
        >
          Return Home
        </Clickable.Text>
      </div>
    </div>
  )
}

export default WhatHappensNext
